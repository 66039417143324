<template>
  <div class="home-popup">

  </div>
</template>
<script>
// import { mapGetters, mapState } from 'vuex'
export default {
  name: 'spacePage',
  components: {
  },
  props: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {

  },
}
</script>
<style scoped>
</style>