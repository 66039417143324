<template>
  <div class="popup-radio">
    <div class="popup-radio-top">
      <div class="radio-top-box">
        <div class="popup-radio-title"><i></i>广播设备情况</div>
        <div class="radio-chart">
          <div class="radio-chart-title">
            <div class="radio-chart-name">
              <i></i>
              <span class="radio-chart-text">在线</span>
              <span class="radio-chart-num">75</span>
            </div>
            <div class="radio-chart-name">
              <i></i>
              <span class="radio-chart-text">离线</span>
              <span class="radio-chart-num">2</span>
            </div>
          </div>
          <div class="radio-chart-box">
            <div class="radio-base-root radio-base-root-color"></div>
            <div class="radio-base-face">
              <img :src="img.pole.broad.media.device" />
            </div>
            <chartsGauge :option="radioChartsGauge1" id="radioChartsGauge1" :key="timer"></chartsGauge>
          </div>
        </div>
      </div>
      <div class="radio-top-box">
        <div class="popup-radio-title"><i></i>话筒设备情况</div>
        <div class="radio-chart">
          <div class="radio-chart-title">
            <div class="radio-chart-name">
              <i></i>
              <span class="radio-chart-text">在线</span>
              <span class="radio-chart-num">75</span>
            </div>
            <div class="radio-chart-name">
              <i></i>
              <span class="radio-chart-text">离线</span>
              <span class="radio-chart-num">2</span>
            </div>
          </div>
          <div class="radio-chart-box">
            <div class="radio-base-root radio-base-root-color"></div>
            <div class="radio-base-face">
              <img :src="img.pole.broad.media.phone" />
            </div>
            <chartsGauge :option="radioChartsGauge2" id="radioChartsGauge2" :key="timer"></chartsGauge>
          </div>
        </div>
      </div>
      <div class="radio-top-box">
        <div class="popup-radio-title"><i></i>储存空间</div>
        <div class="radio-chart">
          <div class="radio-chart-title">
            <div class="radio-chart-name">
              <i></i>
              <span class="radio-chart-text">已使用</span>
              <span class="radio-chart-num">75</span>
            </div>
            <div class="radio-chart-name">
              <i></i>
              <span class="radio-chart-text">未使用</span>
              <span class="radio-chart-num">2</span>
            </div>
          </div>
          <div class="radio-chart-work">
            <div class="radio-chart-capacity">
              <p class="chart-capacity-title">总容量</p>
              <p class="chart-capacity-value">273GB</p>
            </div>
            <chartsPie :option="chartsPieData1" id="radioWorkOrder" :key="timer"></chartsPie>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-radio-root">
      <div class="radio-root-box">
        <div class="popup-radio-title"><i></i>任务计划</div>
        <div class="radio-root-table analyse-table">
          <u-table :data="list" border style="width: 100%;height:100%">
            <u-table-column prop="taskName" label="任务名称" width="80" align="center">
            </u-table-column>
            <u-table-column prop="paramName" label="设备名称" width="80" align="center">
            </u-table-column>
            <u-table-column prop="userName" label="发布人" width="80" align="center">
            </u-table-column>
            <u-table-column prop="confirmUserName" label="审核人" width="80" align="center">
            </u-table-column>
            <u-table-column prop="deployTime" label="发布时间" align="center">
            </u-table-column>
          </u-table>
        </div>
      </div>
      <div class="radio-root-box">
        <div class="popup-radio-title"><i></i>节目预览</div>
        <div class="radio-root-chart">
          <div class="radio-root-left">
            <div class="radio-item-title">
              <span>可用节目数</span>
              <i class="color1">11</i>
            </div>
            <div class="radio-item-title">
              <span>已使用节目数</span>
              <i>26</i>
            </div>
            <div class="radio-item-chart">
              <chartsGauge :option="chartsPieData3" id="chartsPieDevice33" :key="timer"></chartsGauge>
            </div>
          </div>
          <div class="radio-root-right">
            <div class="radio-item-title">
              <span>可使用资源数</span>
              <i class="color2">11</i>
            </div>
            <div class="radio-item-title">
              <span>已使用资源数</span>
              <i>15</i>
            </div>
            <div class="radio-item-chart">
              <chartsGauge :option="chartsPieData4" id="chartsPieDevice44" :key="timer"></chartsGauge>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapGetters, mapState } from 'vuex'
export default {
  name: 'spacePage',
  components: {
  },
  props: {
  },
  data() {
    return {
      timer: 0,
      radioChartsGauge2: {
        lineStyleColor: '#54CF83',
        color: '#3CBF6E',
        width: "15",
        setSeries: {},
        data: [{
          value: 3,
          detail: {
            show: false,
            valueAnimation: true,
            offsetCenter: ['0%', '0%']
          }
        }],
      },
      radioChartsGauge1: {
        lineStyleColor: '#80C7FF',
        color: '#007EFF',
        width: "15",
        setSeries: {},
        data: [{
          value: 3,
          detail: {
            show: false,
            valueAnimation: true,
            offsetCenter: ['0%', '0%']
          }
        }],
      },
      chartsPieData3: {
        lineStyleColor: '#EBF6F6',
        color: '#55CBCC',
        width: "15",
        setSeries: {
          progress: {
            show: true,
            overlap: false,
            roundCap: false,//圆角或 平角false
            clip: false,
          },
          title: {
            color: '#EBF6F6',
            fontSize: 14
          },
          detail: {
            width: 50,
            height: 18,
            fontSize: 14,
            color: '#fff',
            borderColor: 'auto',
            formatter: '{value}%'
          },
        },
        data: [{
          value: 13,
          name: '使用率',
          title: {
            offsetCenter: ['0%', '22%']
          },
          detail: {
            show: true,
            offsetCenter: ['0%', '-15%']
          }
        }],
      },
      chartsPieData4: {
        lineStyleColor: '#EBF6F6',
        color: '#50A2FF',
        width: "16",
        setSeries: {
          progress: {
            show: true,
            overlap: false,
            roundCap: false,//圆角或 平角false
            clip: false,
          },
          title: {
            color: '#EBF6F6',
            fontSize: 14
          },
          detail: {
            width: 50,
            height: 18,
            fontSize: 14,
            color: '#fff',
            borderColor: 'auto',
            formatter: '{value}%'
          },
        },

        data: [{
          value: 13,
          name: '使用率',
          title: {
            offsetCenter: ['0%', '22%']
          },
          detail: {
            show: true,
            offsetCenter: ['0%', '-15%']
          }
        }],
      },
      chartsPieData1: {
        color: ["#4DCB95", "#4688FF", "#e4ca30", "#576ffc"],
        series: [
          { value: 37, name: "已使用", itemStyle: { color: "#4DCB95" }, label: { show: false } },
          { value: 25, name: "未使用", itemStyle: { color: "#4688FF" }, label: { show: false } },
        ],
        setSeries: {
          type: "pie",
          radius: [50, 80],
          center: ["50%", "50%"],
          roseType: "area",
        },
      },
      list: [
        { "taskName": "112", "paramName": "MLS001", "userName": "编辑", "confirmUserName": "amdmin", "deployTime": "2022-04-20 10:58:11" },
        { "taskName": "112", "paramName": "MLS001", "userName": "编辑", "confirmUserName": "amdmin", "deployTime": "2022-04-20 10:58:11" },
        { "taskName": "112", "paramName": "MLS001", "userName": "编辑", "confirmUserName": "amdmin", "deployTime": "2022-04-20 10:58:11" },
        { "taskName": "112", "paramName": "MLS001", "userName": "编辑", "confirmUserName": "amdmin", "deployTime": "2022-04-20 10:58:11" },
      ]
    }
  },
  computed: {
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
    // this.getSpaceInfo();
  },
  methods: {
    getSpaceInfo: function () {
      this.$axios.get(`//${this.domains.trans}/pole/broad/storage`).then(res => {
        if (res.code != 0) {
          return;
        }
        this.$set(this, 'stoarge', res.data);
        this.$set(this.chartsPieData1.series[0], "value", res.data.useage);
        this.$set(this.chartsPieData1.series[0], "name", `已使用`);
        this.$set(this.chartsPieData1.series[1], "value", res.data.free);
        this.$set(this.chartsPieData1.series[1], "name", `未使用`);
        this.timer = new Date().getTime();
      });
    },
  },
}
</script>
<style scoped>
.popup-radio {
  width: 100%;
  height: 100%;
}
.popup-radio-top {
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
}
.popup-radio-root {
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
  margin-top: 14px;
}
.radio-top-box {
  width: 335px;
  height: 249px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.radio-root-box {
  width: 520px;
  height: 278px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.popup-radio-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.popup-radio-title i {
  width: 8px;
  height: 8px;
  background: #3080f5;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.radio-chart {
  display: flex;
  flex-direction: inherit;
  justify-content: space-between;
}
.radio-chart-title {
  margin-top: 42px;
}

.radio-chart-box {
  width: 164px;
  height: 164px;
  background: rgba(128, 199, 255, 0.2);
  border-radius: 50%;
  position: relative;
}
.radio-chart-work {
  width: 164px;
  height: 164px;
  position: relative;
}
.radio-base-face {
  width: 84px;
  height: 84px;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  margin-top: -42px;
  left: 50%;
  margin-left: -42px;
  text-align: center;
  line-height: 110px;
}
#radioChartsGauge1 {
  width: 180px;
  height: 180px;
  position: absolute;
  top: -8px;
  left: -7px;
}
#radioChartsGauge2 {
  width: 180px;
  height: 180px;
  position: absolute;
  top: -8px;
  left: -7px;
}
.radio-chart-name {
  margin-left: 20px;
  margin-top: 10px;
}
.radio-chart-name i {
  width: 10px;
  height: 10px;
  border: 2px solid #3cbf6e;
  border-radius: 50%;
  display: inline-block;
}
.radio-chart-text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 0 10px;
}
.radio-chart-num {
  font-size: 16px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #007cfb;
}
.radio-chart-capacity {
  position: absolute;
  text-align: center;
  z-index: 300;
  width: 100%;
  top: 55px;
}
.chart-capacity-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bbcfec;
}
.chart-capacity-value {
  font-size: 20px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.analyse-table {
  width: 100%;
  margin-top: 22px;
}
.analyse-table >>> .plTableBox .el-table th {
  background: #0f386f;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 6px 0;
}

.analyse-table >>> .plTableBox .el-table tr {
  background-color: transparent;
}
.analyse-table >>> .plTableBox .el-table td {
  background-color: transparent;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  padding: 6px 0;
}
.analyse-table
  >>> .plTableBox
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: transparent;
}
.analyse-table >>> .plTableBox .el-table,
.analyse-table >>> .plTableBox .el-table__expanded-cell {
  background-color: transparent;
}
.radio-root-chart {
  display: flex;
}
.radio-root-left {
  width: 50%;
  margin-top: 10px;
  position: relative;
}
.radio-root-left::after {
  content: "";
  border-right: dashed 1px white;
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 10px;
}
.radio-root-right {
  width: 50%;
  margin-top: 10px;
}
.radio-item-title {
  margin-top: 5px;
}
.radio-item-title span {
  width: 85px;
  height: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  margin-left: 46px;
  margin-right: 20px;
}
.radio-item-title i {
  font-size: 18px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #e8eff7;
  font-style: inherit;
}
.radio-item-title i.color1 {
  color: #55cbcc;
}
.radio-item-title i.color2 {
  color: #50a2ff;
}
.radio-item-chart {
  width: 150px;
  height: 150px;
  margin: 5px 0 0 40px;
}
</style>