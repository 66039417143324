<template>
  <div class="space-box">
    <Carousel v-model="carouselIndex" loop :autoplay="envData.length>1">
      <template v-for='time,index in envData'>
        <CarouselItem :key="index">
          <div class="top">
            <div class="title">设备名称：<span>{{time.name}}</span></div>
            <div class="address"><img :src="img.lightMainLocation" alt="">{{envName}}</div>
          </div>
          <div class='con-box'>
            <div class="con">
              <div class="right">
                <div class="norm">
                  <div class="norm-title">PM<i class="min">2.5</i></div>
                  <div class="norm-size">{{img.val(time.lastData.pm25)}}<span>μg/m3</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
                <div class="norm">
                  <div class="norm-title">PM<i class="min">10</i></div>
                  <div class="norm-size">{{img.val(time.lastData.pm10)}}<span>μg/m3</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
                <div class="norm">
                  <div class="norm-title">噪音</div>
                  <div class="norm-size">{{img.val(time.lastData.n)}}<span>分贝</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
                <div class="norm">
                  <div class="norm-title">风速</div>
                  <div class="norm-size">{{img.val(time.lastData.spd)}}<span>m/s</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
                <div class="norm">
                  <div class="norm-title">风力</div>
                  <div class="norm-size">{{img.val(time.lastData.pow)}}<span>级</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
                <div class="norm">
                  <div class="norm-title">大气压</div>
                  <div class="norm-size">{{img.val(time.lastData.pre)}}<span>P</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
                <div class="norm">
                  <div class="norm-title">温度</div>
                  <div class="norm-size">{{img.val(time.lastData.t)}}<span> ℃</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
                <div class="norm">
                  <div class="norm-title">湿度</div>
                  <div class="norm-size">{{img.val(time.lastData.h)}}<span>RH%</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
                <div class="norm">
                  <div class="norm-title">亮度</div>
                  <div class="norm-size">{{img.val(time.lastData.lux)}}<span>lux</span></div>
                  <div class="norm-many">
                    <div class="norm-than"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CarouselItem>
      </template>
    </Carousel>
  </div>
</template>
<script>
export default {
  name: 'workPage',
  components: {
  },
  data() {
    return {
      many: 20,
      carouselIndex: 0,
      envName: '',
      envData: [],
    }
  },
  computed: {
  },
  mounted: function () {
    this.getEnv();
  },
  methods: {
    getEnv: function () {
      this.$axios.post(`//${this.domains.trans}/pole/home/QueryEnv`, {}).then(res => {
        if (res.code == 0) {
          let list = {
            "id": 0,
            "name": "--",
            "lastData": {
              "time": 1672804808002,
              "spd":-1,
              "pow": -1,
              "dl": -1,
              "da": 0,
              "h":-1,
              "t":-1,
              "n": -1,
              "pm25": -1,
              "pm10": -1,
              "pre": -1,
              "lux": -1,
              "rain": -1
            }
          }
          this.$set(this, 'envData', res.data.list);
          if (res.data.list.length==0) {
            this.envData.push(list)
          }
          this.envName = res.data.area
        }
      })
    },
  },
}
</script>
<style scoped>
.space-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.top {
  display: flex;
  justify-content: space-between;
}
.top .title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c3d8f6;
  line-height: 46px;
  margin-left: 25px;
}
.top .title span {
  color: #00ffcc;
}
.address {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #5293f5;
  line-height: 46px;
  margin-right: 25px;
}
.con {
  width: 100%;
  height: 200px;
  display: flex;
}
.con .left {
  width: 180px;
  height: 150px;
  position: relative;
}
.left .unit {
  position: absolute;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00eaff;
  left: 43%;
  top: 67%;
}
.left .pollute {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00ccff;
  line-height: 46px;
  text-align: center;
}
.left .value {
  position: absolute;
  font-size: 27px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
  left: 41%;
  top: 74%;
}
#main {
  width: 100%;
  height: 150px;
}
.right {
  width: 100%;
}
.norm {
  width: 33%;
  padding-left: 10px;
  float: left;
  margin-bottom: 25px;
}
.norm-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #00ccff;
  line-height: 10px;
}
.min {
  font-size: 12px;
  font-style: inherit;
}
.norm-size {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.norm-size span {
  font-size: 14px;
  color: #00ccff;
  margin-left: 3px;
  vertical-align: top;
}
.norm-many {
  width: 70px;
  height: 5px;
  background: #1e3262;
  border-radius: 3px;
}
.norm-than {
  width: 30%;
  height: 5px;
  background: #7affd0;
  border-radius: 3px;
}
</style>