<template>
  <div class="popup-lamppost">
    <div class="popup-lamppost-top">
      <div class="popup-lamppost-box">
        <div class="popup-lamppost-title"><i></i>基础信息</div>
        <div class="popup-lamppost-num">
          <div class="popup-lamppost-state">
            <span class="color1">1</span>
            <p>总安装</p>
          </div>
          <div class="popup-lamppost-state">
            <span class="color2">0</span>
            <p>在线</p>
          </div>
          <div class="popup-lamppost-state">
            <span class="color3">0</span>
            <p>亮灯</p>
          </div>
          <div class="popup-lamppost-state">
            <span class="color4">0</span>
            <p>故障</p>
          </div>
        </div>
        <div class="lamppost-basics-lower">
          <div class="lamppost-basics-pie">
            <chartsPie :option="chartsPieData" id="chartsPeoplePie" :key="timer"></chartsPie>
          </div>
          <div class="lamppost-basics-chartData">
            <div>
              <i class="lamppost-colorBlock"></i>
              <span class="lamppost-name">用电量1</span>
              <span class="lamppost-num">30</span>
              <span class="lamppost-unit">KWh</span>
            </div>
            <div>
              <i class="lamppost-colorBlock2"></i>
              <span class="lamppost-name">用电量2</span>
              <span class="lamppost-num2">25</span>
              <span class="lamppost-unit">KWh</span>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-lamppost-box">
        <div class="popup-lamppost-title"><i></i>属性信息</div>
        <div class="lamppost-attribute">
          <img :src="img.pole.home.popup.lamppost.lamp" alt="">
          <div class="lamppost-attribute-time">
            <Progress :percent="45" status="active" hide-info></Progress>
            <div>
              <span class="lamppost-attribute-name">亮灯时长</span>
              <span class="lamppost-attribute-value">00:00</span>
            </div>
          </div>
        </div>
        <div class="lamppost-list">
          <div class="lamppost-list-box">
            <div class="lamppost-attribute-box">
              <img :src="img.pole.home.popup.lamppost.brightness" alt="">
              <span class="lamppost-attribute-title">亮度</span>
              <span class="lamppost-attribute-num">80</span>
              <span class="lamppost-attribute-title">L</span>
            </div>
            <div class="lamppost-attribute-box">
              <img :src="img.pole.home.popup.lamppost.voltage" alt="">
              <span class="lamppost-attribute-title">电压</span>
              <span class="lamppost-attribute-num">80</span>
              <span class="lamppost-attribute-title">V</span>
            </div>
            <div class="lamppost-attribute-box">
              <img :src="img.pole.home.popup.lamppost.power" alt="">
              <span class="lamppost-attribute-title">功率</span>
              <span class="lamppost-attribute-num">80</span>
              <span class="lamppost-attribute-title">W</span>
            </div>
          </div>
          <div class="lamppost-list-box">
            <div class="lamppost-attribute-box">
              <img :src="img.pole.home.popup.lamppost.temperature" alt="">
              <span class="lamppost-attribute-title">亮度</span>
              <span class="lamppost-attribute-num">5000</span>
              <span class="lamppost-attribute-title">K</span>
            </div>
            <div class="lamppost-attribute-box">
              <img :src="img.pole.home.popup.lamppost.current" alt="">
              <span class="lamppost-attribute-title">电流</span>
              <span class="lamppost-attribute-num">500</span>
              <span class="lamppost-attribute-title">A</span>
            </div>
            <div class="lamppost-attribute-box">
              <img :src="img.pole.home.popup.lamppost.powerFactor" alt="">
              <span class="lamppost-attribute-title">功率</span>
              <span class="lamppost-attribute-num">30</span>
            </div>
          </div>
        </div>
      </div>
      <div class="popup-lamppost-box">
        <div class="popup-lamppost-title"><i></i>照明操作</div>
        <div class="lamppost-operation">
          <a href="javascript:void(0);" class="lamppost-operation-but" target="_self">
            <img :src="img.pole.home.popup.lamppost.openLamp" alt="">开灯
          </a>
          <a href="javascript:void(0);" class="lamppost-operation-but" target="_self">
            <img :src="img.pole.home.popup.lamppost.shutLamp" alt="">关灯
          </a>
        </div>
        <div>
          <span class="lamppost-operation-title">亮度调节</span>
          <span class="lamppost-operation-num">50%</span>
        </div>
        <Slider v-model="sliderVal"></Slider>
      </div>
    </div>
    <div class="popup-lamppost-line">
      <div class="popup-lamppost-title"><i></i>电量趋势<span>单位：KWh</span></div>
      <div class="lamppost-line">
        <chartsLine :option="chartsLineData" id="lamppostLineData" :key="timer"></chartsLine>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'popupLamppost',
  components: {
  },
  props: {
  },
  data() {
    return {
      timer: 22,
      chartsPieData: {
        id: "chartsPeoplePie",
        series: [
          { value: 30, name: "用电量1" },
          { value: 25, name: "用电量2" },
        ],
        color: ["#3E8CFF", "#0AFFB7"],
        content: {
        },
        setSeries: {
          type: 'pie',
          radius: ['55%', '91%'],
          label: {
            show: false,
            position: 'center'
          },
        }
      },
      chartsLineData: {
        id: "lamppostLineData",
        color: ["#00CCFF"],
        x: ["7-12", "7-13", "7-14", "7-15", "7-16", "7-17"],
        unit: '',
        series: [
          {
            name: '电量趋势',
            data: [60, 35, 80, 20, 65, 84, 65],
          },
        ],
        cover: {
        },
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {}
        }
      },
      sliderVal: 50,
    }
  },
  computed: {
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {

  },
}
</script>
<style scoped>
.popup-lamppost {
  width: 1129px;
  height: 560px;
}
.popup-lamppost-top {
  display: flex;
  flex-direction: inherit;
  justify-content: space-between;
}
.popup-lamppost-box {
  width: 367px;
  height: 263px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.popup-lamppost-line {
  width: 1129px;
  height: 276px;
  margin-top: 14px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.lamppost-line {
  width: 100%;
  height: 214px;
}
.popup-lamppost-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.popup-lamppost-title i {
  width: 8px;
  height: 8px;
  background: #3080f5;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.popup-lamppost-title span {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #4c80c8;
  line-height: 18px;
  margin-left: 10px;
}
.popup-lamppost-num {
  display: flex;
  flex-direction: inherit;
  justify-content: space-between;
  text-align: center;
  margin-top: 6px;
  padding: 0 20px;
}

.popup-lamppost-state span {
  font-size: 24px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #00ccff;
}
.popup-lamppost-state .color1 {
  color: #00ccff;
}
.popup-lamppost-state .color2 {
  color: #0affb7;
}
.popup-lamppost-state .color3 {
  color: #f4d042;
}
.popup-lamppost-state .color4 {
  color: #db4041;
}
.popup-lamppost-state p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.lamppost-basics-lower {
  display: flex;
  flex-direction: inherit;
  justify-content: flex-start;
  margin-top: 22px;
  margin-left: 12px;
}
.lamppost-basics-pie {
  width: 120px;
  height: 120px;
}
.lamppost-basics-chartData {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 24px;
}
.lamppost-colorBlock {
  width: 8px;
  height: 8px;
  background: #0affb7;
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 2px;
}
.lamppost-colorBlock2 {
  width: 8px;
  height: 8px;
  background: #3e8cff;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 2px;
}
.lamppost-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.lamppost-num {
  font-size: 22px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #0affb7;
  margin: 0 10px;
}
.lamppost-num2 {
  font-size: 22px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #3e8cff;
  margin: 0 10px;
}
.lamppost-unit {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.lamppost-attribute {
  display: flex;
  flex-direction: inherit;
  justify-content: flex-start;
}
.lamppost-attribute img {
  margin: 20px;
}
.lamppost-attribute-time {
  width: 238px;
  padding-top: 20px;
}
.lamppost-attribute-name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  margin-top: 6px;
}
.lamppost-attribute-value {
  font-size: 20px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #00ccff;
  float: right;
}
.lamppost-list {
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
  width: 100%;
}
.lamppost-list-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.lamppost-attribute-box {
  display: flex;
  flex-direction: revert;
  justify-content: flex-start;
  margin-top: 10px;
}
.lamppost-attribute-box img {
  width: 18px;
  height: 18px;
  margin-top: 2px;
}
.lamppost-attribute-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 10px;
}
.lamppost-attribute-num {
  font-size: 24px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #00ccff;
  margin-top: -11px;
  margin-left: 7px;
}
.lamppost-operation {
  padding: 20px;
}
.lamppost-operation-but {
  width: 72px;
  height: 30px;
  display: inline-block;
  background: #2f7ef2;
  border-radius: 15px;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 30px;
  margin-right: 21px;
}
.lamppost-operation-but img {
  vertical-align: sub;
  margin-right: 5px;
}
.lamppost-operation-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
}
.lamppost-operation-num {
  font-size: 20px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #00ccff;
  float: right;
}
</style>