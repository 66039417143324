<template>
  <div class="pole-main">
    <!-- szamdmSmartLamp  Key:e63909bc52070768fd72e902c7d67da8   安全密钥:3c6032e54cdb1b50ed2a66ebc5b3d220 -->
    <div id="container"></div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      map: null
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {
  },
  created: function () {
  },
  mounted: function () {
    this.initMap()
  },
  methods: {
    initMap() {
      AMapLoader.reset();
      // console.log("user------------")
      // console.log(this.user)
      window._AMapSecurityConfig = { securityJsCode: '3c6032e54cdb1b50ed2a66ebc5b3d220' };
      AMapLoader.load({
        "key": "e63909bc52070768fd72e902c7d67da8", // 申请好的Web端开发者Key，首次调用 load 时必填
        "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        "plugins": ['AMap.Scale'],           // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          viewMode: "3D",    //是否为3D地图模式
          zoom: 17.5,           //初始化地图级别
          pitch: 75,//倾斜角度
          center: [113.829358, 22.770886], //初始化地图中心点位置
          mapStyle: "amap://styles/45065e3b35240e12b1ac34eca2b4502b"
        });
      }).catch(e => {
        console.log(e);
      })
    }
  },
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 100%;
}
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}
</style>