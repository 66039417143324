<template>
  <div class="pole-main">
    <div class="title">单灯总数: <span>{{lightData.count}}</span> 个</div>
    <div class="list">
      <div class="list-box">
        <div class="power-detail-item">
          <img :src="img.pole.homePage.OnlineNum">
          <div>
            <span class="grey">正常运行</span>
            <span class="no-opacity">{{lightData.online}}<i class="grey">个</i></span>
          </div>
        </div>
        <div class="power-detail-item">
          <img :src="img.pole.homePage.LightupNum">
          <div>
            <span class="grey">亮灯数量</span>
            <span class="no-opacity">{{lightData.running}}<i class="grey">个</i></span>
          </div>
        </div>
      </div>
      <div class="list-box">
        <div class="power-detail-item">
          <img :src="img.pole.homePage.alarmsNum">
          <div>
            <span class="grey">报警数量</span>
            <span class="no-opacity">{{lightData.alarm}}<i class="grey">个</i></span>
          </div>
        </div>
        <div class="power-detail-item">
          <img :src="img.pole.homePage.repairNum">
          <div>
            <span class="grey">维修数量</span>
            <span class="no-opacity">{{lightData.repair}}<i class="grey">个</i></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'lightingPage',
  components: {
  },
  data() {
    return {
      lightData: {
        alarm: 0,
        count: 0,
        online: 0,
        repair: 0,
        running: 0,
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
    this.getLight();
  },
  methods: {
    getLight: function () {
      this.$axios.post(`//${this.domains.trans}/pole/home/QueryLight`, {}).then(res => {
        if (res.code == 0) {
          this.lightData = res.data;
        }
      })
    },
  },
}
</script>
<style scoped>
.pole-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c3d8f6;
  line-height: 18px;
  margin-left: 30px;
}
.title span {
  font-size: 20px;
  color: #00ccff;
  line-height: 18px;
}
.list {
  height: 138px;
  display: flex;
  flex-flow: column;
}
.list-box {
  margin: 13px 0 12px 5px;
  display: flex;
  justify-content: space-between;
}
.power-detail-item {
  width: 168px;
  height: 50px;
  display: flex;
  align-items: center;
}
.power-detail-item img {
  margin: 0 10px 0 30px;
}
.power-detail-item div {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.grey {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c3d8f6;
  font-style: inherit;
}
.no-opacity {
  font-size: 20px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.no-opacity .grey {
  margin-left: 5px;
}
</style>