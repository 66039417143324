<template>
  <div class="pole-main">
    <!-- <div class="energy-title">
      <div class="title-menu">
        <div :class="['title-menu-item',menuIndex == 0 ? 'title-menu-active' : '',]" @click="switchMenuIndex(0)">近7日
        </div>
        <div :class="['title-menu-item',menuIndex == 1 ? 'title-menu-active' : '',]" @click="switchMenuIndex(1)">近1个月
        </div>
        <div :class="['title-menu-item',menuIndex == 2 ? 'title-menu-active' : '',]" @click="switchMenuIndex(2)">近2个月
        </div>
        <div :class="['title-menu-item',menuIndex == 3 ? 'title-menu-active' : '',]" @click="switchMenuIndex(3)">近3个月
        </div>
      </div>
    </div> -->
    <div class="energy-content">
      <chartsLine :option="chartsLineData" :key="timer" v-if="isShow"></chartsLine>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'lightingPage',
  components: {
  },

  data() {
    return {
      menuIndex: 0,
      timer: "",
      isShow: true,
      chartsLineData: {
        id: "chartsLine",
        x: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun5"],
        unit: '',
        yBorderColor: 'rgba(34, 51, 83, 0.45)',
        series: [
          {
            name: '当日能耗(KWh)',
            data: [150, 230, 224, 218, 135, 147, 222],
          }
        ],
        xColor: "#8491AE",
        yColor: "#8491AE",
        cover: {
          grid: {
            top: "25%", // 等价于 y: '16%'
            left: "0%",
            right: "0%",
            bottom: "8%",
            containLabel: true,
          },
        },
        setSeries: {
          smooth: false, //平滑折线图
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#408EFF", // 0% 处的颜色
                },
                {
                  offset: 0.9,
                  color: "#090D1A", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          }//下阴影
        }
      },
      energyData: {},
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {
    windowOnResize() {
      this.widthChange();
    },
  },
  created: function () {
  },
  mounted: function () {
    this.getEnergy();
  },
  methods: {
    getEnergy: function () {
      this.chartsLineData.x=[];
      this.chartsLineData.series[0].data=[];
      this.$axios.post(`//${this.domains.trans}/pole/home/QueryEnergy`, {}).then(res => {
        if (res.code == 0) {
          this.energyData = res.data;
          res.data.forEach(el => {
            this.chartsLineData.x.push(el.time)
            this.chartsLineData.series[0].data.push(el.energyValue)
          });

          this.widthChange();
        }
      })
    },
    switchMenuIndex(num) {
      this.menuIndex = num;
    },
    widthChange() {
      this.isShow = false;
      this.timer = new Date().getTime();
      this.isShow = true;
    },
  },
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 200px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.energy-title {
  height: 40px;
  flex: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0cd3fc;
  position: absolute;
  right: -10px;
  top: 4px;
  z-index: 1000;
}
.title-menu {
  margin: 0 10px;
  display: flex;
  width: 245px;
  flex: none;
}
.title-menu-active {
  background: rgba(2, 138, 242, 0.5);
  color: #ffffff;
}
.title-menu-item {
  width: 61px;
  height: 22px;
  border: solid 1px #028af2;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #68b7f0;
}
.energy-content {
  flex: auto;
}
</style>