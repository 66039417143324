<template>
  <div class="popup-video">
    <div class="video-edge-box">
      <div class="video-edge-upper">
        <div class="popup-video-title"><i></i>实时人流量</div>
        <div class="video-edge-data">
          <div class="video-edge-con video-edge-conBorder">
            <p>
              <span>32</span>人
            </p>
            <p>总人流量</p>
          </div>
          <div class="video-edge-con">
            <p>
              <span>6</span>人
            </p>
            <p>今日人流量</p>
          </div>
        </div>
        <div class="video-edge-chart">
          <div class="video-edge-pie">
            <chartsPie :option="chartsPieData1" id="chartsPeoplePie1" :key="timer"></chartsPie>
          </div>
          <div class="video-edge-chartData">
            <div>
              <i class="video-colorBlock"></i>
              <span class="video-name">男</span>
              <span class="video-num">30</span>
              <span class="video-name">个</span>
            </div>
            <div>
              <i class="video-colorBlock2"></i>
              <span class="video-name">女</span>
              <span class="video-num2">25</span>
              <span class="video-name">个</span>
            </div>
          </div>
        </div>
      </div>
      <div class="video-edge-lower">
        <div class="popup-video-title"><i></i>年龄分布</div>
        <div class="video-edge-chart">
          <div class="video-edge-pie">
            <chartsPie :option="chartsPieData2" id="chartsPeoplePie2" :key="timer"></chartsPie>
          </div>
          <div class="video-edge-chartData">
            <div>
              <i class="video-colorBlock video-age-Color1"></i>
              <span class="video-name">11-20岁</span>
              <span class="video-num video-age-numColor1">30</span>
              <span class="video-name">%</span>
            </div>
            <div>
              <i class="video-colorBlock video-age-Color2"></i>
              <span class="video-name">21-40岁</span>
              <span class="video-num video-age-numColor2">30</span>
              <span class="video-name">%</span>
            </div>
            <div>
              <i class="video-colorBlock video-age-Color3"></i>
              <span class="video-name">41-60岁</span>
              <span class="video-num video-age-numColor3">30</span>
              <span class="video-name">%</span>
            </div>
            <div>
              <i class="video-colorBlock video-age-Color4"></i>
              <span class="video-name">61岁以上</span>
              <span class="video-num video-age-numColor4">30</span>
              <span class="video-name">%</span>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="video-middle-box">
      <div id="vehicleId" class="video-player" :key="timer"></div>
    </div>
    <div class="video-edge-box">
      <div class="video-edge-upper">
        <div class="popup-video-title"><i></i>实时车流量</div>
        <div class="video-edge-data">
          <div class="video-edge-con video-edge-conBorder">
            <p>
              <span>32</span>辆
            </p>
            <p>总车流量</p>
          </div>
          <div class="video-edge-con">
            <p>
              <span>6</span>辆
            </p>
            <p>今日车流量</p>
          </div>
        </div>
        <div class="video-edge-chart">
          <div class="video-edge-pie">
            <chartsPie :option="chartsPieData3" id="chartsPeoplePie3" :key="timer"></chartsPie>
          </div>
          <div class="video-edge-chartData">
            <div>
              <i class="video-colorBlock vehicleColor1"></i>
              <span class="video-name">面包车</span>
              <span class="video-num video-vehicle-color1">30</span>
              <span class="video-name">辆</span>
            </div>
            <div>
              <i class="video-colorBlock vehicleColor2"></i>
              <span class="video-name">SUV</span>
              <span class="video-num video-vehicle-color2">18</span>
              <span class="video-name">辆</span>
            </div>
            <div>
              <i class="video-colorBlock vehicleColor3"></i>
              <span class="video-name">轿车</span>
              <span class="video-num video-vehicle-color3">30</span>
              <span class="video-name">辆</span>
            </div>
            <div>
              <i class="video-colorBlock vehicleColor4"></i>
              <span class="video-name">其他</span>
              <span class="video-num video-vehicle-color4">25</span>
              <span class="video-name">辆</span>
            </div>
          </div>
        </div>
      </div>
      <div class="video-edge-lower">
        <div class="popup-video-title"><i></i>轨迹搜索</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import EZUIKit from 'ezuikit-js';
export default {
  name: 'spacePage',
  components: {
  },
  props: {
  },
  data() {
    return {
      timer: 0,
      player: null,
      chartsPieData1: {
        id: "chartsPeoplePie1",
        series: [
          { value: 30, name: "男" },
          { value: 25, name: "女" },
        ],
        color: ["#3E8CFF", "#0AFFB7"],
        content: {
        },
        setSeries: {
          type: 'pie',
          radius: ['55%', '91%'],
          label: {
            show: false,
            position: 'center'
          },
        }
      },
      chartsPieData2: {
        id: "chartsPeoplePie2",
        series: [
          { value: 30, name: "11-20岁" },
          { value: 25, name: "21-40岁" },
          { value: 25, name: "41-60岁" },
          { value: 25, name: "61岁以上" },
        ],
        color: ["#60BDFF", "#8AFDA2", "#F8AA57", "#F67C8F"],
        content: {
        },
        setSeries: {
          type: 'pie',
          radius: ['55%', '91%'],
          label: {
            show: false,
            position: 'center'
          },
        }
      },
      chartsPieData3: {
        id: "chartsPeoplePie3",
        series: [
          { value: 30, name: "面包车" },
          { value: 25, name: "SUV" },
          { value: 25, name: "轿车" },
          { value: 25, name: "其他" },
        ],
        color: ["#9C42FF", "#F6706C", "#DECB00", "#4A3AE7"],
        content: {
        },
        setSeries: {
          type: 'pie',
          radius: ['55%', '91%'],
          label: {
            show: false,
            position: 'center'
          },
        }
      },
      devices: [],
    }
  },
  computed: {
    ...mapState('group', ['selectedNode', 'selectedDevices']),
    ...mapState('cmd', ['windowOnResize']),
    ...mapState('common', ['productCodes', 'powerBoxTypes', 'mapCenter']),
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
    this.getVideos();
  },
  methods: {
    getVideos: function () {
      let poleId = 0, groupId = 0;
      if(this.selectedNode.type != this.productCodes.station){
        groupId = this.selectedNode.id;
      }else{
        poleId = this.selectedNode.poleId;
      }
      this.$axios.post(`//${this.domains.trans}/pole/config/QueryCameraList`, {groupId, poleId}).then(res => {
        if (res.code != 0) {
          // this.$Message.error(res.data);
          return;
        }
        res.data.map(p => {
          if(!p.content){
            p.content = {};
          }
          if(!p.content.tasks){
            p.content.tasks = [];
          }
        })
        this.$set(this, 'devices', res.data);
        this.urlAjax();
      })
    },
    urlAjax: async function () {
      if (this.devices.length <= 0) { return false }
      let ajaxData = this.devices[0];
      this.player = null;
      let res = await this.$axios.get(`//${this.domains.trans}/video/preview/${ajaxData.id}?code=${ajaxData.content.code}`);
      this.timer = new Date().getTime();
      if (res.code !== 0) {
        return;
      }
      setTimeout(() => {
        let id = "vehicleId";
        let v = document.getElementById(id);
        let token = res.data.token;
        let url = res.data.url;
        let rect = v.getBoundingClientRect();
        this.player = new EZUIKit.EZUIKitPlayer({
          id: id,
          accessToken: token,
          url: url,
          autoplay: true,
          width: rect.width,
          height: rect.height,
        });
        v.style.overflow = 'hidden';
      }, 100);
    },
  },
}
</script>
<style scoped>
.popup-video {
  width: 100%;
  height: 487px;
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
}
.video-edge-box {
  width: 353px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.video-edge-upper {
  width: 353px;
  height: 263px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.video-edge-lower {
  width: 353px;
  height: 210px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.video-middle-box {
  width: 655px;
  height: 487px;
  border-radius: 10px;
}
.popup-video-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.popup-video-title i {
  width: 8px;
  height: 8px;
  background: #3080f5;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.video-edge-data {
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
}
.video-edge-con {
  width: 50%;
  height: 57px;
  box-sizing: border-box;
}
.video-edge-con p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}
.video-edge-con p span {
  font-size: 24px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #00c8fb;
}
.video-edge-conBorder {
  border-right: 1px solid #255491;
}
.video-edge-chart {
  display: flex;
  flex-direction: inherit;
  justify-content: flex-start;
  margin-top: 25px;
  margin-left: 12px;
}
.video-edge-pie {
  width: 120px;
  height: 120px;
}
.video-edge-chartData {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 30px;
}
.video-colorBlock {
  width: 8px;
  height: 8px;
  background: #0affb7;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 2px;
}
.video-colorBlock2 {
  width: 8px;
  height: 8px;
  background: #2f7ef2;
  display: inline-block;
  margin-right: 12px;
  margin-bottom: 2px;
}
.video-colorBlock.vehicleColor1 {
  background: #9c42ff;
}
.video-colorBlock.vehicleColor2 {
  background: #f6706c;
}
.video-colorBlock.vehicleColor3 {
  background: #decb00;
}
.video-colorBlock.vehicleColor4 {
  background: #4a3ae7;
}
.video-num.video-vehicle-color1 {
  color: #9c42ff;
}
.video-num.video-vehicle-color2 {
  color: #f6706c;
}
.video-num.video-vehicle-color3 {
  color: #decb00;
}
.video-num.video-vehicle-color4 {
  color: #4a3ae7;
}
.video-name {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.video-num {
  font-size: 22px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #0affb7;
  margin: 0 10px;
}
.video-num2 {
  font-size: 22px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #2f7ef2;
  margin: 0 10px;
}
.video-colorBlock.video-age-Color1 {
  background: #60bdff;
}
.video-colorBlock.video-age-Color2 {
  background: #8afda2;
}
.video-colorBlock.video-age-Color3 {
  background: #f8aa57;
}
.video-colorBlock.video-age-Color4 {
  background: #f67c8f;
}
.video-num.video-age-numColor1 {
  color: #60bdff;
}
.video-num.video-age-numColor2 {
  color: #8afda2;
}
.video-num.video-age-numColor3 {
  color: #f8aa57;
}
.video-num.video-age-numColor4 {
  color: #f67c8f;
}
.video-player {
  width: 100%;
  height: 100%;
}
</style>