<template>
  <div class="popup-wifi">
    <div class="wifi-left-box">
      <div class="popup-wifi-title"><i></i>当前连接设备</div>
      <div class="wifi-device-box">
        <div class="wifi-device-name device-border">
          <div class="device-name">设备名</div>
        </div>
        <div class="wifi-device-name">
          <div class="device-mac">
            MAC地址
          </div>
        </div>
      </div>
    </div>
    <div class="wifi-right-box">
      <div class="popup-wifi-title"><i></i>流量统计</div>
      <div class="wifi-census-icon">
        <div class="wifi-icon-box">
          <div class="wifi-icon-list wifiBg1"><img :src="img.light.wifi.crowd1" alt=""></div>
          <div class="wifi-icon-val">
            <p class="wifi-icon-name">今日流量</p>
            <p class="wifi-icon-num">200</p>
          </div>
        </div>
        <div class="wifi-icon-box">
          <div class="wifi-icon-list wifiBg2"><img :src="img.light.wifi.crowd2" alt=""></div>
          <div class="wifi-icon-val">
            <p class="wifi-icon-name">今日人次</p>
            <p class="wifi-icon-num">300</p>
          </div>
        </div>
        <div class="wifi-icon-box">
          <div class="wifi-icon-list wifiBg3"><img :src="img.light.wifi.crowd3" alt=""></div>
          <div class="wifi-icon-val">
            <p class="wifi-icon-name">累计流量</p>
            <p class="wifi-icon-num">5100</p>
          </div>
        </div>
        <div class="wifi-icon-box">
          <div class="wifi-icon-list wifiBg4"><img :src="img.light.wifi.crowd4" alt=""></div>
          <div class="wifi-icon-val">
            <p class="wifi-icon-name">累计人次</p>
            <p class="wifi-icon-num">200</p>
          </div>
        </div>
      </div>
      <div class="wifi-census-search">
        <span>时间</span>
        <DatePicker v-model="form.start" type="date" placeholder="请选择开始时间" style="width: 148px; margin:0 10px;"></DatePicker>
        <span>至</span>
        <DatePicker v-model="form.end" type="date" placeholder="请选择截止时间" style="width: 148px; margin:0 10px;"></DatePicker>
        <a href="javascript:void(0);" class="wifi-census-ban"> <img :src="img.light.factory.light.lampList.but8" alt="">
          查询</a>
        <div class="wifi-census-type">
          <a href="javascript:void(0);" class="wifi-census-ban1 wifi-census-num" :class="[censusType==0?'active':'']" @click="censusType=0">人数</a>
          <a href="javascript:void(0);" class="wifi-census-ban1 wifi-census-flow" :class="[censusType==1?'active':'']" @click="censusType=1">流量</a>
        </div>
      </div>
      <div class="wifi-census-chart">
        <chartsLine :option="chartsLineData" id="peopleLineData" :key="timer"></chartsLine>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'spacePage',
  components: {
  },
  props: {
  },
  data() {
    return {
      censusType: 0,
      form: {
        start: "",
        end: ""
      },
      chartsLineData: {
        id: "peopleLineData",
        color: ["#00CCFF", "#6A7BFA"],
        x: ["1", "3", "5", "7", "9", "11"],
        unit: '个',
        series: [
          {
            name: '统计',
            data: [60, 35, 80, 20, 65, 84, 65],
          },
        ],
        setSeries: {
          smooth: true, //平滑折线图
          // areaStyle: {}//下阴影
        }
      }
    }
  },
  computed: {
  },
  watch: {

  },
  created: function () {
  },
  mounted: function () {
    let now = new Date();
    this.form.end = now.format('yyyy-MM-dd');
    now.setDate(now.getDate() - 1);
    this.form.start = now.format('yyyy-MM-dd');
  },
  methods: {

  },
}
</script>
<style scoped>
.popup-wifi {
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
}
.wifi-left-box {
  width: 353px;
  height: 540px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}
.wifi-right-box {
  width: 755px;
  height: 540px;
  background: rgba(1, 20, 45, 0.5);
  border-radius: 10px;
  padding: 20px;
}

.popup-wifi-title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.popup-wifi-title i {
  width: 8px;
  height: 8px;
  background: #3080f5;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.wifi-device-box {
  display: flex;
  flex-direction: inherit;
  justify-content: space-around;
  margin-top: 20px;
}
.wifi-device-name {
  width: 50%;
  box-sizing: border-box;
  text-align: center;
  height: 22px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
}
.wifi-device-name.device-border {
  text-indent: 20px;
  text-align: left;
  border-right: 1px solid #27599b;
}
.wifi-census-icon {
  display: flex;
  flex-direction: inherit;
  justify-content: space-evenly;
  margin-top: 20px;
}
.wifi-icon-box {
  display: flex;
  flex-direction: inherit;
  justify-content: flex-start;
  width: 24%;
}
.wifi-icon-list {
  width: 42px;
  height: 42px;
  border-radius: 8px;
  text-align: center;
  line-height: 48px;
  margin-left: 12px;
}
.wifi-icon-list.wifiBg1 {
  background: linear-gradient(0deg, #30c17e, #79e07b);
}
.wifi-icon-list.wifiBg2 {
  background: linear-gradient(0deg, #2e87f5, #6cdbfb);
}
.wifi-icon-list.wifiBg3 {
  background: linear-gradient(0deg, #fbaa37, #ffe683);
}
.wifi-icon-list.wifiBg4 {
  background: linear-gradient(0deg, #eb638c, #ff9595);
}
.wifi-icon-val {
  margin-left: 20px;
  margin-top: -3px;
}
.wifi-icon-val .wifi-icon-name {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.wifi-icon-val .wifi-icon-num {
  font-size: 20px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #ffffff;
}
.wifi-census-search {
  margin-top: 40px;
  padding-left: 15px;
}
.wifi-census-search span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.wifi-census-ban {
  width: 67px;
  height: 30px;
  background: #007eff;
  border-radius: 4px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  margin-left: 10px;
}
.wifi-census-ban img {
  vertical-align: middle;
}
.wifi-census-type {
  float: right;
}
.wifi-census-ban1 {
  width: 52px;
  height: 28px;
  background: #133f7c;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 28px;
  display: inline-block;
}
.wifi-census-num {
  border-radius: 4px 0px 0px 4px;
}
.wifi-census-flow {
  border-radius: 0px 4px 4px 0px;
}
.wifi-census-ban1.active {
  background: linear-gradient(180deg, #4890fb, #025ee5);
}
.wifi-census-chart {
  width: 100%;
  height: 310px;
}
</style>