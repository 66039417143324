<template>
  <div class="home-popup">
    <template v-for="item in effectList">
      <component v-if="item.key == isKey && item.com" :key="item.key" v-bind:is="item.com" :args="item.args"></component>
    </template>
  </div>
</template>
<script>
import info from './info'
import lamppost from './lamppost'
import police from './police'
import myRadio from './radio'
import myVidel from './videl'
import humid from './humid'
import myWifi from './wifi'
export default {
  name: 'spacePage',
  components: {
  },
  props: {
    isKey: {
      type: String,
      default: "s1",
    },
  },
  data() {
    return {
      isShowKye: "",
      effectList: [
        { name: "灯杆", key: "s1", com: lamppost, args: {} },
        { name: "安防监控", key: "s2", com: myVidel, args: {} },
        { name: "广播", key: "s3", com: myRadio, args: {} },
        { name: "报警系统", key: "s4", com: police, args: {} },
        { name: "信息屏", key: "s5", com: info, args: {} },
        { name: "环境温湿度", key: "s6", com: humid, args: {} },
        { name: "Wifi", key: "s7", com: myWifi, args: {} },
      ],
    }
  },
  computed: {
  },
  watch: {
    isKey() {
      this.isShowKye = this.isKey;
    }
  },
  created: function () {
  },
  mounted: function () {
    this.isShowKye = this.isKey;
    // console.log(this.isKey)
  },
  methods: {

  },
}
</script>
<style scoped>
</style>