<template>
  <div class="pole-main">
    <div class="rotation">
      <div class="leftBut">
        <img :src="img.pole.homePage.leftBut" alt="">
      </div>
      <div class="show">
        <div class="con">
          <div class="imgBox" v-if="carousel.length>2">
            <video class="video" autoplay="autoplay" loop="loop" muted="muted"
              :src="carousel[carousel.length-1].url"></video>
          </div>
          <div class="conImg">
            <video class="video" autoplay="autoplay" loop="loop" muted="muted" :src="carousel[0].url"></video>
          </div>
          <div class="imgBox" v-if="carousel.length>1">
            <video class="video" autoplay="autoplay" loop="loop" muted="muted" :src="carousel[1].url"></video>
          </div>
        </div>
      </div>
      <div class="rigBut">
        <img :src="img.pole.homePage.rigBut" alt="">
      </div>
    </div>
    <div class="title">
      当前播放：<span>科技时代（8s）</span>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'spacePage',
  components: {
  },
  data() {
    return {
      carousel: [
        { index: 0, url: "http://www.amdm.top/api/center/station/file/media?id=0309d83b1a0c4a49b2bfe1bef77ed497&auth=62a086d6d462140100b1417110da94d3", },
        { index: 1, url: "http://www.amdm.top/api/center/station/file/media?id=0309d83b1a0c4a49b2bfe1bef77ed497&auth=62a086d6d462140100b1417110da94d3", },
        { index: 2, url: "http://www.amdm.top/api/center/station/file/media?id=0309d83b1a0c4a49b2bfe1bef77ed497&auth=62a086d6d462140100b1417110da94d3", },
        { index: 3, url: "http://www.amdm.top/api/center/station/file/media?id=0309d83b1a0c4a49b2bfe1bef77ed497&auth=62a086d6d462140100b1417110da94d3", },
        { index: 4, url: "http://www.amdm.top/api/center/station/file/media?id=0309d83b1a0c4a49b2bfe1bef77ed497&auth=62a086d6d462140100b1417110da94d3", },
        { index: 5, url: "http://www.amdm.top/api/center/station/file/media?id=0309d83b1a0c4a49b2bfe1bef77ed497&auth=62a086d6d462140100b1417110da94d3", },
      ],
      value1: 0,
      ledData:[],
    }
  },
  computed: {
    ...mapGetters('auth', ['custAndType']),
    ...mapState('auth', ['token', 'user', 'customerId', 'customers', 'appTypes', 'config', 'appType', 'agentChangeValue']),
    ...mapState('common', ['weeks']),
    ...mapState('group', ['devices', 'treeData', 'showDeviceTypes']),
  },
  watch: {
  },
  created: function () {
  },
  mounted: function () {
    this.getled();
  },
  methods: {
    getled: function () {
      this.$axios.post(`//${this.domains.trans}/pole/home/QueryLed`, {}).then(res => {
        if (res.code == 0) {
          this.ledData = res.data;
          // console.log('QueryLed--------------')
        }
      })
    },
  },
}
</script>
<style scoped>
.pole-main {
  width: 100%;
  height: 100%;
}
.rotation {
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  justify-content: space-evenly;
}
.leftBut {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.rigBut {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.show {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.con {
  width: 255px;
  height: 150px;
  position: relative;
  display: flex;
  justify-content: space-around;
}
.con .imgBox {
  width: 105px;
  height: 120px;
  opacity: 0.7;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.con .imgBox video {
  max-width: 105px;
  max-height: 120px;
}
.con .conImg {
  width: 105px;
  height: 150px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.con .conImg video {
  max-width: 105px;
  max-height: 150px;
}
.title {
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #c3d8f6;
  line-height: 46px;
}
.title span {
  color: #00ccff;
}
</style>