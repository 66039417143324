var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-video"},[_c('div',{staticClass:"video-edge-box"},[_c('div',{staticClass:"video-edge-upper"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"video-edge-chart"},[_c('div',{staticClass:"video-edge-pie"},[_c('chartsPie',{key:_vm.timer,attrs:{"option":_vm.chartsPieData1,"id":"chartsPeoplePie1"}})],1),_vm._m(2)])]),_c('div',{staticClass:"video-edge-lower"},[_vm._m(3),_c('div',{staticClass:"video-edge-chart"},[_c('div',{staticClass:"video-edge-pie"},[_c('chartsPie',{key:_vm.timer,attrs:{"option":_vm.chartsPieData2,"id":"chartsPeoplePie2"}})],1),_vm._m(4)])])]),_c('div',{staticClass:"video-middle-box"},[_c('div',{key:_vm.timer,staticClass:"video-player",attrs:{"id":"vehicleId"}})]),_c('div',{staticClass:"video-edge-box"},[_c('div',{staticClass:"video-edge-upper"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"video-edge-chart"},[_c('div',{staticClass:"video-edge-pie"},[_c('chartsPie',{key:_vm.timer,attrs:{"option":_vm.chartsPieData3,"id":"chartsPeoplePie3"}})],1),_vm._m(7)])]),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-video-title"},[_c('i'),_vm._v("实时人流量")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-edge-data"},[_c('div',{staticClass:"video-edge-con video-edge-conBorder"},[_c('p',[_c('span',[_vm._v("32")]),_vm._v("人 ")]),_c('p',[_vm._v("总人流量")])]),_c('div',{staticClass:"video-edge-con"},[_c('p',[_c('span',[_vm._v("6")]),_vm._v("人 ")]),_c('p',[_vm._v("今日人流量")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-edge-chartData"},[_c('div',[_c('i',{staticClass:"video-colorBlock"}),_c('span',{staticClass:"video-name"},[_vm._v("男")]),_c('span',{staticClass:"video-num"},[_vm._v("30")]),_c('span',{staticClass:"video-name"},[_vm._v("个")])]),_c('div',[_c('i',{staticClass:"video-colorBlock2"}),_c('span',{staticClass:"video-name"},[_vm._v("女")]),_c('span',{staticClass:"video-num2"},[_vm._v("25")]),_c('span',{staticClass:"video-name"},[_vm._v("个")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-video-title"},[_c('i'),_vm._v("年龄分布")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-edge-chartData"},[_c('div',[_c('i',{staticClass:"video-colorBlock video-age-Color1"}),_c('span',{staticClass:"video-name"},[_vm._v("11-20岁")]),_c('span',{staticClass:"video-num video-age-numColor1"},[_vm._v("30")]),_c('span',{staticClass:"video-name"},[_vm._v("%")])]),_c('div',[_c('i',{staticClass:"video-colorBlock video-age-Color2"}),_c('span',{staticClass:"video-name"},[_vm._v("21-40岁")]),_c('span',{staticClass:"video-num video-age-numColor2"},[_vm._v("30")]),_c('span',{staticClass:"video-name"},[_vm._v("%")])]),_c('div',[_c('i',{staticClass:"video-colorBlock video-age-Color3"}),_c('span',{staticClass:"video-name"},[_vm._v("41-60岁")]),_c('span',{staticClass:"video-num video-age-numColor3"},[_vm._v("30")]),_c('span',{staticClass:"video-name"},[_vm._v("%")])]),_c('div',[_c('i',{staticClass:"video-colorBlock video-age-Color4"}),_c('span',{staticClass:"video-name"},[_vm._v("61岁以上")]),_c('span',{staticClass:"video-num video-age-numColor4"},[_vm._v("30")]),_c('span',{staticClass:"video-name"},[_vm._v("%")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-video-title"},[_c('i'),_vm._v("实时车流量")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-edge-data"},[_c('div',{staticClass:"video-edge-con video-edge-conBorder"},[_c('p',[_c('span',[_vm._v("32")]),_vm._v("辆 ")]),_c('p',[_vm._v("总车流量")])]),_c('div',{staticClass:"video-edge-con"},[_c('p',[_c('span',[_vm._v("6")]),_vm._v("辆 ")]),_c('p',[_vm._v("今日车流量")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-edge-chartData"},[_c('div',[_c('i',{staticClass:"video-colorBlock vehicleColor1"}),_c('span',{staticClass:"video-name"},[_vm._v("面包车")]),_c('span',{staticClass:"video-num video-vehicle-color1"},[_vm._v("30")]),_c('span',{staticClass:"video-name"},[_vm._v("辆")])]),_c('div',[_c('i',{staticClass:"video-colorBlock vehicleColor2"}),_c('span',{staticClass:"video-name"},[_vm._v("SUV")]),_c('span',{staticClass:"video-num video-vehicle-color2"},[_vm._v("18")]),_c('span',{staticClass:"video-name"},[_vm._v("辆")])]),_c('div',[_c('i',{staticClass:"video-colorBlock vehicleColor3"}),_c('span',{staticClass:"video-name"},[_vm._v("轿车")]),_c('span',{staticClass:"video-num video-vehicle-color3"},[_vm._v("30")]),_c('span',{staticClass:"video-name"},[_vm._v("辆")])]),_c('div',[_c('i',{staticClass:"video-colorBlock vehicleColor4"}),_c('span',{staticClass:"video-name"},[_vm._v("其他")]),_c('span',{staticClass:"video-num video-vehicle-color4"},[_vm._v("25")]),_c('span',{staticClass:"video-name"},[_vm._v("辆")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"video-edge-lower"},[_c('div',{staticClass:"popup-video-title"},[_c('i'),_vm._v("轨迹搜索")])])
}]

export { render, staticRenderFns }