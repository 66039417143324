<template>
  <div class="container" :style="{backgroundImage: `url(${img.pole.home.bg2})`}">
    <div class="below">
      <!-- <gisPage /> -->
    </div>
    <div class="above">
      <div class="side">
        <div class="modular">
          <div class="title">
            <div class="cross-bar">
              <div class="box"></div>
              <div class="box"></div>
            </div>
            <div class="name" :style="{backgroundImage:`url('${img.pole.homePage.upperBase}')`}">
              <img :src="img.pole.homePage.upperBaseIcon" alt="">
              <span class="chinese">{{leftMenus[0].name}}</span>
              <span class="english">/ {{leftMenus[0].title}}</span>
            </div>
          </div>
          <spacePage />
          <img :src="img.pole.homePage.lowerBase" alt="">
        </div>
        <div class="modular middle">
          <div class="title">
            <div class="cross-bar">
              <div class="box"></div>
              <div class="box"></div>
            </div>
            <div class="name" :style="{backgroundImage:`url('${img.pole.homePage.upperBase}')`}">
              <img :src="img.pole.homePage.upperBaseIcon" alt="">
              <span class="chinese">{{leftMenus[1].name}}</span>
              <span class="english">/ {{leftMenus[1].title}}</span>
            </div>
          </div>
          <lightingPage />
          <img :src="img.pole.homePage.lowerBase" alt="">
        </div>
        <div class="modular">
          <div class="title">
            <div class="cross-bar">
              <div class="box"></div>
              <div class="box"></div>
            </div>
            <div class="name" :style="{backgroundImage:`url('${img.pole.homePage.upperBase}')`}">
              <img :src="img.pole.homePage.upperBaseIcon" alt="">
              <span class="chinese">{{leftMenus[2].name}}</span>
              <span class="english">/ {{leftMenus[2].title}}</span>
            </div>
          </div>
          <workPage />
          <img :src="img.pole.homePage.lowerBase" alt="">

        </div>
      </div>
      <div class="con">
        <div class="con-gis">
          <core />
        </div>
        <div class="con-low">
          <div class="title">
            <div class="name" :style="{backgroundImage:`url('${img.pole.homePage.upperBasePlus}')`}">
              <img :src="img.pole.homePage.upperBaseIcon" alt="">
              <span class="chinese">{{bottomMenus[0].name}}</span>
              <span class="english">/ {{bottomMenus[0].title}}</span>
            </div>
          </div>
          <energy />
          <img :src="img.pole.homePage.lowerBasePlus" alt="">
        </div>
      </div>
      <div class="side">
        <div class="modular" style="height:265px">
          <div class="title">
            <div class="cross-bar">
              <div class="box"></div>
              <div class="box"></div>
            </div>
            <div class="name" :style="{backgroundImage:`url('${img.pole.homePage.upperBase}')`}">
              <img :src="img.pole.homePage.upperBaseIcon" alt="">
              <span class="chinese">{{rightMenus[0].name}}</span>
              <span class="english">/ {{rightMenus[0].title}}</span>
            </div>
          </div>
          <advert />
          <img :src="img.pole.homePage.lowerBase" alt="" style="width:100%">
        </div>
        <div class="modular middle" style="height:265px">
          <div class="title">
            <div class="cross-bar">
              <div class="box"></div>
              <div class="box"></div>
            </div>
            <div class="name" :style="{backgroundImage:`url('${img.pole.homePage.upperBase}')`}">
              <img :src="img.pole.homePage.upperBaseIcon" alt="">
              <span class="chinese">{{rightMenus[1].name}}</span>
              <span class="english">/ {{rightMenus[1].title}}</span>
            </div>
          </div>
          <videoPage />
          <img :src="img.pole.homePage.lowerBase" alt="">
        </div>
        <div class="modular">
          <div class="title">
            <div class="cross-bar">
              <div class="box"></div>
              <div class="box"></div>
            </div>
            <div class="name" :style="{backgroundImage:`url('${img.pole.homePage.upperBase}')`}">
              <img :src="img.pole.homePage.upperBaseIcon" alt="">
              <span class="chinese">{{rightMenus[2].name}}</span>
              <span class="english">/ {{rightMenus[2].title}}</span>
            </div>
          </div>
          <police />
          <img :src="img.pole.homePage.lowerBase" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import spacePage from './home/space'
import lightingPage from './home/lighting'
import workPage from './home/work'

import advert from './home/advert'
import videoPage from './home/video'
import police from './home/police'
import energy from './home/energy'
import core from './home/core'
// import gisPage from './home/gis'

export default {
  name: 'Home',
  components: {
    spacePage,
    lightingPage,
    workPage,
    videoPage,
    advert,
    energy,
    police,
    core,
    // gisPage,
  },
  data() {
    return {
      leftMenus: [
        { key: "space", name: "环境监测", title: "ENVIRONMENTAL MONITORING", url: "./home/space" },
        { key: "lighting", name: "照明统计", title: "LIGHTING STATISTICS", url: "./home/lighting" },
        { key: "work", name: "工单统计", title: "ENVIRONMENTAL MONITORING", url: "./home/work" },
      ],
      bottomMenus: [
        { key: "energy", name: "能耗统计", title: "ENERGY CONSUMPTION STATISTICS", url: "./home/energy" },
      ],
      rightMenus: [
        { key: "advert", name: "广告屏", title: "ADVERTISING SCREEN", url: "./home/advert" },
        { key: "video", name: "视频监控", title: "VIDEO SURVEILLANCE", url: "./home/video" },
        { key: "police", name: "报警统计", title: "ALARM STATISTICS", url: "./home/police" },
      ],
    }
  },
  watch: {

  },
  mounted: function () {

  },
  destroyed: function () {
  },
  methods: {

  }
}
</script>
<style scoped>
.below {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
}
.above {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 50;
  display: flex;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  justify-content: space-between;
}
.container {
  width: 100%;
  height: 100%;
  padding: 0 20px 10px;
  position: relative;
}
.container::-webkit-scrollbar {
  display: none;
}
.container .side {
  width: 381px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container .con {
  width: calc(100% - 762px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container .modular {
  width: 100%;
  height: 295px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.container .modular.middle {
  height: 230px;
}
.cross-bar {
  width: 100%;
  height: 2px;
  background: #153869;
  display: flex;
  justify-content: space-between;
}
.cross-bar .box {
  width: 13px;
  height: 2px;
  background: #3080f5;
}
.modular .name {
  width: 100%;
  height: 35px;
  margin-top: 1px;
  display: flex;
  justify-content: flex-start;
}
.chinese {
  font-size: 18px;
  font-family: titleBody;
  font-weight: 400;
  color: #cff0ff;
  line-height: 35px;
  margin-left: 4px;
}
.english {
  font-size: 12px;
  font-family: Bahnschrift;
  font-weight: 400;
  color: #6997bc;
  line-height: 35px;
  margin-left: 12px;
}
.con-gis {
  width: 100%;
  height: 100%;
}
.con-low {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0);
}
.con-low .name {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
